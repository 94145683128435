import React, { useState } from "react";
import { Modal, Table, Tabs, Button, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import "./css/StyleReportModal.css";

const { TabPane } = Tabs;
const { Text } = Typography;
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF6384"];

const StyleReportModal = ({ visible, onClose, styleReports }) => {
  const mainCategories = ["Personal Assessment Value", "Body Shape Value", "Face Shape Value"];
  const categorizedData = mainCategories.map((category) => ({
    category,
    data: styleReports.filter((report) => report.category === category),
  }));

  const otherReports = styleReports.filter((report) => !mainCategories.includes(report.category));
  categorizedData.push({ category: "Other Reports", data: otherReports });

  const [activeTab, setActiveTab] = useState(categorizedData[0]?.category || "");


  const columns = [
    { title: "Category", dataIndex: "category", key: "category", render: (_, record) => record.category || "Other" },
    { title: "Value", dataIndex: "value", key: "value" },
    { title: "Occurrences", dataIndex: "occurrence_count", key: "occurrence_count" },
  ];

  const downloadReport = () => {
    const selectedData = categorizedData.find((item) => item.category === activeTab)?.data || [];

    const worksheet = XLSX.utils.json_to_sheet(selectedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, selectedData);
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(data, "StyleReport.xlsx");
  };

  // ✅ State to control Pie Chart Modal
  const [chartModalVisible, setChartModalVisible] = useState(false);
  const [selectedChartData, setSelectedChartData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");


  const openChartModal = (category, data) => {
    const updatedData = data.map((item) => ({
      ...item,
      value: item.value || item.category, // If value is null, use category instead
    }));
  
    setSelectedCategory(category);
    setSelectedChartData(updatedData);
    setChartModalVisible(true);
  };
  

  const COLORS = [
    "#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28DFF",
    "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF",
    "#C9CBCF", "#FF99C8", "#76D7C4", "#F8C471", "#D988B9"
  ];

  const renderCustomizedLabel = ({ name, percent }) => {
    return `${(percent * 100).toFixed(1)}%`;
  };

  const generateUniqueColor = () => {
    return `#${Math.floor(Math.random()*16777215).toString(16)}`;
  };

  const CustomTooltip = ({ active, payload }) => {
    console.log("payload", payload);
    
    if (active && payload?.length) {
      const data = payload.find((item) => item.payload) || {}; // Find the first valid item
      console.log(data, "data");
      const total = 10; // Use precomputed totalOccurrences or fallback to 1
      console.log(total, "total");
  
      const categoryName = data.name || "Unknown"; // Extract category name
      const value = data.value || 0; // Extract the value field
      console.log(value, "value");
  
      const percent = total ? (value / total) * 100 : 0; // Calculate percentage
  
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "8px",
            borderRadius: "5px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
            textAlign: "center",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold", color: "#333" }}>{categoryName}</p>
          <p style={{ margin: 0, color: "#666" }}>{`${value}`}</p>
        </div>
      );
    }
    
    return null;
  };
  
  


  
  const CustomLegend = ({ payload }) => {
    return (
      <div style={{ 
        display: "grid", 
        gridTemplateColumns: "repeat(3, 1fr)", // Ensures 3 per row 
        gap: "8px", // Adds space between items
        alignContent: "normal",
        justifyContent: "normal",
        alignItems: "normal",
        justifyItems: "start", // Aligns items to the start of each column
        marginTop: 10 
      }}>
        {payload.map((entry, index) => (
          <div 
            key={`legend-${index}`} 
            style={{ display: "flex", alignItems: "center" }}
          >
            <div
              style={{
                width: 12,
                height: 12,
                backgroundColor: entry.color,
                marginRight: 5,
                borderRadius: "75%",
              }}
            />
            <span style={{ fontSize: 12, fontWeight: 500,  color: "#555" }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };
  
  

  return (
    <>
<Modal
  title={
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
<span className="style-report-title">Style Report Details</span>
<Button type="primary" icon={<DownloadOutlined />} onClick={downloadReport} className="download-button">
  Download Report
</Button>
    </div>
  }
  visible={visible}
  onCancel={onClose}
  footer={null}
  width={900}
>
        {/* <div className="modal-header">
          <Button type="primary" icon={<DownloadOutlined />} onClick={downloadReport}>
            Download Report
          </Button>
        </div> */}
        <Tabs defaultActiveKey={categorizedData[0]?.category} onChange={setActiveTab}>
          {categorizedData.map((section, index) => (
            <TabPane tab={section.category} key={index}>
              <div style={{ marginBottom: 10, textAlign: "center" }}>
                <Text
                  type="secondary"
                  style={{ cursor: "pointer", fontSize: 16 }}
                  onClick={() => openChartModal(section.category, section.data)}
                >
                  View Chart
                </Text>
              </div>

              <Table columns={columns} dataSource={section.data} rowKey={(record, idx) => idx} pagination={false} />
            </TabPane>
          ))}
        </Tabs>
      </Modal>

      {/* ✅ Pie Chart Modal */}
      <Modal
        title={`${selectedCategory} Distribution`}
        visible={chartModalVisible}
        onCancel={() => setChartModalVisible(false)}
        footer={null}
        width={600}
      >
        {selectedChartData && selectedChartData.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <PieChart >
              <Pie
                data={selectedChartData}
                dataKey="occurrence_count"
                nameKey="value"
                cx="50%"
                cy="50%"
                outerRadius={90}
                paddingAngle={3.5} 
                fill="#8884d8"
              >
                {selectedChartData.map((_, idx) => (
                    <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />        
                     ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} /> 
              <Legend  content={<CustomLegend/>} />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <p style={{ textAlign: "center", color: "#888" }}>No data available for this category.</p>
        )}
      </Modal>
    </>
  );
};

export default StyleReportModal;
