import React from "react";
import "./css/servicecard.css";
import { FaShoppingBag, FaTshirt, FaRing, FaBoxes } from "react-icons/fa"; // Icons for services

const icons = {
  "Shopping Assistant": <FaShoppingBag />,
  "Wardrobe Assistant": <FaTshirt />,
  "Wedding Assistant": <FaRing />,
  "Capsule Wardrobe": <FaBoxes />,
};

const ServiceCard = ({ label, description, onClick }) => {
  return (
    <div className="service-card"  onClick={onClick}>
      <div className="icon-container">{icons[label]}</div>
      <h4>{label}</h4>
      <p className="service-description">{description}</p>
    </div>
  );
};

export default ServiceCard;
