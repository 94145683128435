import React, { useState, useEffect } from "react";
import "./css/CustomerDashboard.css";
import StatCard from "../Components/cards/statcard.jsx";
import CustomerTable from "../Components/cards/CustomerTable.jsx";
import ServiceCard from "../Components/cards/servicecard.jsx";
import PurchasedHistory from "../Components/cards/purchasedhistory.jsx";
import { Spin, notification } from "antd";
import config from "../config.jsx";
import { motion } from "framer-motion";
import axios from "axios";
import StyleReportModal from "../Components/cards/StyleReportModal.jsx";
import { userReturn } from "../utils/encryption.js";

const CustomerDashboard = () => {
  const [showCustomerTable, setShowCustomerTable] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]); // Store original data
  const [allServices, setAllServices] = useState([]); // Store original data
  const [purchasedhistory, setpurchasedHistory] = useState([]); // Store original data
  const [wardrobeImagesList, setWardrobeImagesList] = useState([]); // Store original data
  const [customers, setCustomers] = useState([]); // Store filtered data
  const [purchaseHistoryData, setPurchaseHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeFilterType, setActiveFilterType] = useState(null); // Track active filter type
  const [styleReports, setStyleReports] = useState([]); // Store style reports data
  const [styleReportModalVisible, setStyleReportModalVisible] = useState(false);
  const [wardrobeImagesCount, setWardrobeImagesCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false); // For card flip
  const [orderHistory, setorderedHistory] = useState([]); // Store original data
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));


  const getUsersData = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getUserManagement`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        console.log("fetchedData", fetchedData);
        setFilteredCustomers(fetchedData);
        setAllCustomers(fetchedData); // Store the original data
        setCustomers(fetchedData); 
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load users data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getUserServicesData = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getUserManagementActiveServices`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        console.log("fetchedData", fetchedData);
        setFilteredServices(fetchedData);
        setAllServices(fetchedData); // Store the original data
        // setCustomers(fetchedData); 
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load services data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };


  const getUserStyleReportsData = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getUserManagementStyleReports`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        console.log("fetchedStyleReportsData", fetchedData);
        setStyleReports(fetchedData);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load services data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getUserPurchasedHistory = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getPurchasedHistory`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        console.log("fetchedData", fetchedData);
        setpurchasedHistory(fetchedData); // Store the original data
        // setCustomers(fetchedData); 
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load services data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getTotalWardrobeImages = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getTotalWardrobeImagesUpload`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset[0];
        console.log("fetchedDatafromtotalwardrobe", fetchedData, fetchedData.wardrobe_images_Count, fetchedData.Today_Count);

        setWardrobeImagesCount(fetchedData.wardrobe_images_Count);
        setTodayCount(fetchedData.Today_Count);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load services data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };


  const getTotalWardrobeImages1 = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getTotalWardrobeImagesUploadlist`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        console.log("fetchedDatafromtotalwardrobelist", fetchedData);
        setWardrobeImagesList(fetchedData);
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load services data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getTotalOrderHistory = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getUsermanagementOrderHistory`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        console.log("fetchedDatafromtotalwardrobe", fetchedData);
        setorderedHistory(fetchedData);

      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to load services data.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

    useEffect(() => {
      getUsersData();
      getUserServicesData();
      getUserPurchasedHistory();
      getUserStyleReportsData();
      getTotalWardrobeImages();
      getTotalWardrobeImages1();
      getTotalOrderHistory();
    }, []);
 
  const filterCustomers = (role) => {
    setFilteredCustomers(allCustomers.filter(customer => customer.ROLE === role));
    setFilteredServices([]); // Clear services
    setActiveFilterType("customer");
    setShowCustomerTable(true);
  };

  const getFilteredCount = (role) => {
    return customers.filter((customer) => customer.ROLE === role).length;
  };

  const filterServices = (serviceId) => {
    setFilteredServices(allServices.filter(service => service.SERVICE_ID === serviceId));
    setFilteredCustomers([]); // Clear customers
    setActiveFilterType("service");
    setShowCustomerTable(true);
  };

  const filterPurchaseHistory = () => {
    setPurchaseHistoryData(purchasedhistory);
    setFilteredCustomers([]); // Clear customers
    setFilteredServices([]); // Clear services
    setActiveFilterType("purchaseHistory");
    setShowCustomerTable(true);
  };

  const filterOrderHistory = () => {
    setorderedHistory(orderHistory);
    setFilteredCustomers([]); // Clear customers
    setFilteredServices([]); // Clear services
    setPurchaseHistoryData([]); // Clear previous purchase history
    setActiveFilterType("orderHistory"); // Set filter type to order history
    setShowCustomerTable(true);
  };
  

  const showWardrobeImagesList = () => {
    setWardrobeImagesList(wardrobeImagesList);
    setFilteredServices([]); // Clear services
    setPurchaseHistoryData([]); // Clear purchase history
    setFilteredCustomers([]); // Clear customers
    setorderedHistory([]); // Clear order history
    setActiveFilterType("wardrobeImages");
    setShowCustomerTable(true);
  };
  const getServiceCount = (serviceId) => {
    return allServices.filter(service => service.SERVICE_ID === serviceId).length;
  };

  const toggleCustomerTable = () => {
    setShowCustomerTable(false);
  };
  

  return (
    <div className="dashboard-container">
      <div className="header">
        <h2>User Management</h2>
      </div>

      <div className="stats">
        <>{console.log("stylereportcount", styleReports.length > 0 ? styleReports[0].occurrence_count : 0)}</>
        <StatCard label="Customers" value={getFilteredCount("1")}  description="To get all customers" onClick={() => filterCustomers("1")} />
        <StatCard label="Admins" value={getFilteredCount("0")} description="To get Admin Details" onClick={() => filterCustomers("0")} />
        <StatCard label="Stylists" value={getFilteredCount("2")} description="To get Stylists" onClick={() => filterCustomers("2")} />
        <StatCard label="Style Report"   value={styleReports.length > 0 ? styleReports[0].occurrence_count : 0} description="To view all style reports"  onClick={() => setStyleReportModalVisible(true)} />
        <StatCard label="Wardrobe Images" value={wardrobeImagesCount}   description="Total images uploaded"    onClick={handleFlip} isFlipped={isFlipped}
flipContent={
  <div style={{ 
    backgroundColor: "#31363f", 
    color: "white", 
    padding: "20px", 
    borderRadius: "10px", 
    minHeight: "100px",
    width: "100%", 
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <div>
        <h3>Total users uploaded wardrobe Images</h3>
        <p>{wardrobeImagesCount}</p>
      </div>
      <div>
        <h3>Today Count</h3>
        <p>{todayCount}</p>
      </div>
    </div>
    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
      <button 
        onClick={handleFlip} 
        style={{ 
          backgroundColor: "white", 
          color: "black", 
          border: "none", 
          padding: "5px 10px", 
          borderRadius: "5px", 
          cursor: "pointer" 
        }}>
        Back
      </button>
      <button 
       onClick={showWardrobeImagesList}
        style={{ 
          backgroundColor: "#4CAF50", 
          color: "white", 
          border: "none", 
          padding: "5px 10px", 
          borderRadius: "5px", 
          cursor: "pointer" 
        }}>
        Get List
      </button>
    </div>
  </div>
}

/>



      </div>

      <div className="services-section">
      <div className="header">
        <h3>Active Services</h3>
      </div>


        <div className="services">
          <ServiceCard label="Shopping Assistant" description={`Active Users: ${getServiceCount(1)}`} onClick={() => filterServices(1)} />
          <ServiceCard label="Wardrobe Assistant" description={`Active Users: ${getServiceCount(2)}`} onClick={() => filterServices(2)} />
          <ServiceCard label="Wedding Assistant" description={`Active Users: ${getServiceCount(3)}`} onClick={() => filterServices(3)} />
          <ServiceCard label="Capsule Wardrobe" description={`Active Users: ${getServiceCount(4)}`} onClick={() => filterServices(4)} />
        </div>
      </div>

      
      <div className="purchase-history-section">
  <div className="header">
    <h3>Order History</h3>
  </div>
  <div className="purchase-history">
    <PurchasedHistory
      label="Service History"
      value={purchasedhistory.length}
      description="View all service history"
      onClick={() => filterPurchaseHistory("service")}
    />
    <PurchasedHistory
      label="Order History"
      value={orderHistory.length}
      description="View all order history"
      onClick={() => filterOrderHistory()}
    />
  </div>
</div>



      {showCustomerTable && (
  <>
    {console.log(orderHistory, "purchadehistorydata")}  {/* Log the purchaseHistoryData */}
    <CustomerTable
      customers={filteredCustomers}
      services={filteredServices}
      purchaseHistoryData={purchasedhistory} 
      orderHistory={orderHistory} // Ensure correct order history is passed
      wardrobeImagesList={wardrobeImagesList}
      closeTable={toggleCustomerTable}
      activeFilterType={activeFilterType}
    />
  </>
)}

{/* Style Report Modal */}
<StyleReportModal
        visible={styleReportModalVisible}
        onClose={() => setStyleReportModalVisible(false)}
        styleReports={styleReports}
      />
    </div>

  
  );
};

export default CustomerDashboard;
