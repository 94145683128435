import React from "react";
import { FaArrowRight } from "react-icons/fa"; // Adding an explore icon (You can use any icon package you prefer)
import "./css/statcard.css";

const StatCard = ({ label, value, description, onClick , isFlipped, flipContent }) => {
  return (
    <div className={`stat-box ${isFlipped ? "flipped" : ""}`} onClick={onClick}> {/* Add the onClick event here */}
     
     {!isFlipped ? (
        <>
          <div className="stat-header">
            <div className="stat-label">{label}</div>
          </div>
          <div className="stat-description">{description}</div>
          <div className="divider"></div>
          <div className="stat-footer">
            <div className="stat-count">{value}</div>
            <div className="explore-icon">
              <FaArrowRight />
            </div>
          </div>
        </>
      ) : (
        <div className="flipped-content">{flipContent}</div>
      )}
    </div>
  );
};

export default StatCard;
