import React, { useState, useEffect, useContext } from "react";
import UserDisplayCard from "../cards/UserDisplayCard";
import "./css/New.css";
import {
  DatePicker,
  Button,
  Typography,
  Row,
  Col,
  Spin,
  message,
  Input,
} from "antd";
import axios from "axios";
import config from "../../config";
import Popup from "../cards/Popup";
import Assigned from "./Assigned";
import { notification, Space } from "antd";
import { NotificationContext } from "../../App";
import { userReturn } from "../../utils/encryption";

function New({ AssignedSylzData }) {
  const { Search } = Input;
  const [shoptype, setShopType] = useState("Shopping");
  const [showStylz, setShowStylz] = useState(false);
  const [UseData, setUserData] = useState([]);
  const [SelectUserData, setSelectUserData] = useState([]);
  const [StylzList, setStylzList] = useState([]);
  const [AssignedUser, setAssignedUser] = useState([]);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [loading, setloading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState(""); // New state for search query
  const [filteredData, setFilteredData] = useState([]); // New state for filtered and sorted data
  

  const fcmToken = sessionStorage.getItem("fcmToken");
  const { refreshData } = useContext(NotificationContext); // Get context value


  const openNotificationWithIcon = (type, msg) => {
    api[type]({
      message: "Notification Title",
      description: msg,
      duration: 10,
    });
  };

  const getUnAssignedDetails = async (userId) => {
    try {
      
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      if (!accessToken) {
        console.error("Access token not found");
        throw new Error("Unauthorized: No access token found");
      }

      const response = await axios.get(
        `${config.baseURL}getUnAssignedDetails/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(userId),
          },
        }
      );

      if (response?.data?.recordset) {
        const data = response?.data?.recordset;

        setFilteredData(data);
        setUserData(data);
        setloading(false);

      }
      return response.data;
    } catch (error) {
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };

  const getStylistList = async (userId) => {
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage
      const response = await axios.get(`${config.baseURL}getStylistList`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(userId),
          },
        }

      );
      if (response?.data?.recordset) {
        setStylzList(response?.data?.recordset);
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching stylist list:", error);
      throw error;
    }
  };

  const getCustomerDetails = async (userId) => {
    try {
            const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getCustomerDetails/${userId}`,
         {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(userId),
          },
        }
      );
      if (response?.data?.recordset) {
        setAssignedUser(response?.data?.recordset);
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  useEffect(() => {
    getUnAssignedDetails(LoginUser?.USER_ID);
  }, [LoginUser?.USER_ID]);

  useEffect(() => {
    getStylistList(LoginUser?.USER_ID);
  }, [LoginUser?.USER_ID]);

  useEffect(() => {
    if (LoginUser?.USER_ID) {
      getUnAssignedDetails(LoginUser.USER_ID);
    }
  }, [refreshData]);

  // Update filteredData whenever search or UseData changes


  useEffect(() => {
    console.log(UseData, "Original UseData");

    const filtered = UseData.filter((user) => {
      const firstName = user?.first_name || "No Name";
      return firstName.toLowerCase().includes(search.toLowerCase());
    });

    console.log(filtered, "Filtered Data");

    const sortedData = filtered.sort((a, b) => {
      const nameA = a?.first_name || "No Name";
      const nameB = b?.first_name || "No Name";

      return nameA.localeCompare(nameB);
    });

    console.log(sortedData, "Sorted Data");



    console.log(sortedData, "Unique Data before setting state");

    setFilteredData(sortedData);

  }, [search]);



  const onClick = (data) => {
    setSelectUserData(data);
    if (data) {
      setShowStylz(true);
    }
  };

  const handleStyleClick = async (data) => {

    await AssignedStylz(data);
  };

  const AssignedStylz = async (data) => {
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.post(
        `${config.baseURL}stylistAssign`,
        new URLSearchParams({
          STYLIST_ID: data?.USER_ID.toString(),
          USER_ID: SelectUserData?.user_id.toString(),
          STATUS: "1",
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.message === "success") {
        await AssignedStylzNotifytouser(data);
        await AssignedStylzNotifytoBackend(data);
        await AssignedStylzNotifytoBackend1(data);

      }
      return response.data;
    } catch (error) {
      console.error("Error assigning stylist:", error);
      throw error;
    }
  };

  const AssignedStylzNotifytouser = async (data) => {
    // await AssignedStylzNotifytoBackend(data);
    AssignedSylzData(SelectUserData);
    await getCustomerDetails(LoginUser?.USER_ID);
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.post(
        `${config.baseURL}getNotifyUsers`,
        new URLSearchParams({
          fcm_token: SelectUserData?.fcm_token,
          title: "Skanda Admin",
          body: "A stylist has been assigned to you",
          service_id: SelectUserData.SERVICE_SHORT_CODE,
          orderId: SelectUserData.user_service_id,
          amount: "",
          transaction_id: ""
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.status === 201) {
        await AssignedStylzNotifytostylist(data);
      }
      return response.data;
    } catch (error) {
      console.error("Error notifying user:", error);
    }
  };

  const AssignedStylzNotifytostylist = async (data) => {
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.post(
        `${config.baseURL}getNotifyStylist`,
        new URLSearchParams({
          fcm_token: data.FCM_TOKEN,
          title: "Skanda Admin",
          body: "A stylist has been assigned to you",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.status === 201) {
        openNotificationWithIcon("success", "Assigned successfully.");
      }
      return response.data;
    } catch (error) {
      console.error("Error notifying stylist:", error);
    }
  };

  const AssignedStylzNotifytoBackend = async (data) => {
    console.log(data, 'data...............')
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          USER_ID: SelectUserData?.user_id.toString(),
          NOTI_TITLE: "Skanda Admin",
          NOTI_BODY: "A stylist has been assigned to you",
          APP_TYPE: "stylz",
          NOTI_STATUS: "0",
          STYLIST_ID: data?.USER_ID?.toString(),
          SERVICE_ID: SelectUserData?.SERVICE_SHORT_CODE,
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
          CUSTOMER_NAME: SelectUserData?.first_name,
          CUSTOMER_ID: SelectUserData?.user_id.toString(),
          FCM_TOKEN: SelectUserData?.fcm_token
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.status === 200) {
        getUnAssignedDetails(LoginUser?.USER_ID);
        openNotificationWithIcon("success", "Assigned successfully.");
        setShowStylz(!showStylz);
      }
      return response.data;
    } catch (error) {
      console.error("Error notifying backend:", error);
    }
  };

  const AssignedStylzNotifytoBackend1 = async (data) => {
    console.log(data, 'data...............')
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.post(
        `${config.baseURL}notificationService`,
        new URLSearchParams({
          USER_ID:    data?.USER_ID?.toString(),
          NOTI_TITLE: "Skanda Admin",
          NOTI_BODY: "A User has been assigned to you",
          APP_TYPE: "stylist",
          NOTI_STATUS: "0",
          STYLIST_ID: data?.USER_ID?.toString(),
          SERVICE_ID: SelectUserData?.SERVICE_SHORT_CODE,
          USER_SERVICE_ID: SelectUserData?.user_service_id.toString(),
          CUSTOMER_NAME: SelectUserData?.first_name,
          CUSTOMER_ID: SelectUserData?.user_id.toString(),
          FCM_TOKEN: SelectUserData?.fcm_token
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.status === 200) {
        getUnAssignedDetails(LoginUser?.USER_ID);
        openNotificationWithIcon("success", "Assigned successfully.");
        setShowStylz(!showStylz);
      }
      return response.data;
    } catch (error) {
      console.error("Error notifying backend:", error);
    }
  };

  return (
    <div className="heading-container">
      <h1 className="heading">Customer Information Ready for Service</h1>
      <div
        className="filter-container"
        style={{
          margin: "0.5rem 2% -0.5rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Search
          placeholder="Search by name"
          onChange={(e) => setSearch(e.target.value)}
          // style={{ width: 200 }}
          allowClear
        />
      </div>
      <div className="body-section" style={{
        marginTop: "0",
      }}>


        {!loading ? (
          <>
            {filteredData.length > 0 ? (
              filteredData.map((user, index) => (
                <UserDisplayCard
                  key={user.USER_SERVICE_ID} // Ensure this is unique
                  image={user.user_photo}
                  name={user.first_name}
                  purchasedate={user.start_date}
                  dob={user.age}
                  category={user.service_title}
                  user={user}
                  stylistName={user.stylist_name}
                  buttonText={"Assign"}
                  background={user?.service_category === "Digital Wardrobe"
                    ? "#FBF1E9"
                    : "#ffffbc"
                  }
                  onClick={() => onClick(user)}
                />
              ))
            ) : (
              <p className="nouserassigned" key="no-data">No results found.</p>
            )}
          </>
        ) : (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        )}

        {showStylz && StylzList && (
          <Popup
            namesList={StylzList}
            onStylzClick={(data) => {
              // console.log(data,'data...............')
              handleStyleClick(data);
            }}
            onClose={() => {

              setShowStylz(!showStylz);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default New;
