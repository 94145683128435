import React, { useState, useEffect } from "react";
import { Spin, notification } from "antd";
import axios from "axios";
import config from "../config";
import "./css/referenceImages.css";
import { userReturn } from "../utils/encryption";

const ReferenceImages = ({ SelectUserData }) => {
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));


  // Fetch previously selected reference images
  const getPreviouslyReference = async () => {
    setLoading(true);
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getReferenceImages/${SelectUserData.user_id}/${SelectUserData.SERVICE_SHORT_CODE}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      
      console.log(response?.data, "Full API Response");
  
      // Extracting the data from response
      if (response?.data?.recordset?.length > 0) {
        const selectedImagesList = response.data.recordset.map(item => ({
          id: item.REFERENCE_ID,
          image: item.REFERENCE_IMAGE,
          serviceName: item.REFERENCE_SERVICE_NAME,
          eventType: item.EVENT_TYPE,
          gender: item.GENDER,
          createdDate: item.CREATED_DATE
        }));
  
        console.log(selectedImagesList, "Mapped Data");
  
        setSelectedItems(selectedImagesList);
      } else {
        console.log("No reference images found.");
      }
    } catch (error) {
      console.error("Error fetching previously selected items:", error);
    } finally {
      setLoading(false);
    }
  };
  function extractAllOptionTexts(jsonString) {
    try {
      // Check if the input is already a string with extra quotes
      if (typeof jsonString === "string") {
        jsonString = jsonString.replace(/^"|"$/g, ""); // Remove outer quotes if they exist
      }
  
      const jsonData = JSON.parse(jsonString);
  
      const optionTexts = jsonData
        .flatMap(item => Object.values(item)) // Extract all values from objects
        .flat() // Flatten nested arrays
        .filter(option => typeof option === "object" && option.optionText) // Filter objects that have optionText
        .map(option => option.optionText.trim().replace(/^"|"$/g, "")); // Trim and remove extra quotes
  
      return optionTexts.join(", "); // Join multiple options with commas
    } catch (error) {
      console.error("Invalid JSON format:", error);
      return jsonString.replace(/^"|"$/g, ""); // Return cleaned string if JSON parsing fails
    }
  }
  
  

  const subcategoriesInSelectedCategory = [
    ...new Set(selectedItems.map((item) => item.OUTFIT_TEXT)),
  ];

  // Handle subcategory selection
  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  // Fetch data on component mount
  useEffect(() => {
    getPreviouslyReference();
  }, []);

  // Capitalize the first letter of a string
  const capitalizeFirstLetter = (str) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

  return (
    <div>
      <Spin spinning={loading} tip="Loading...">
        {/* Subcategory buttons */}

        {/* Display images in subcategory */}
<div className="reference-images-container">
  <Spin spinning={loading} tip="Loading...">
    {/* Subcategory buttons */}

    {/* Display images in subcategory */}
    <div className="subcategory-images">
      {selectedItems.length > 0 ? (
        selectedItems
          .filter(
            (item) =>
              !selectedSubcategory || item.eventType === selectedSubcategory
          )
          .map((item, index) => (
            <div
              key={item.id || index} // Ensure a unique key
              className="subcategory-item"
              onClick={() => console.log(`Selected item: ${item.serviceName}`)}
              draggable
              onDragStart={(e) =>
                e.dataTransfer.setData(
                  "application/json",
                  `${config.imageUrl}${item.image}`
                )
              }
            >
              <img
                src={`${config.imageUrl}${item.image}`}
                alt={item.serviceName}
              />

              {/* Separate Section for Event Type */}
              <div className="event-type">
  <strong className="event-type-label">Event Type:</strong>
  <span className="event-options">
    {extractAllOptionTexts(item.eventType)
      .split(", ") // Split by commas to create an array
      .map((text, index) => (
        <React.Fragment key={index}>
          <br /> {text}
        </React.Fragment>
      ))}
  </span>
</div>



              {/* Separate Section for Created Date */}
              <div className="created-date">
  <strong>Uploaded Date:</strong> {item.createdDate ? item.createdDate.split("  ")[0] : "No Date Found"}
</div>

            </div>
          ))
      ) : (
        <p className="AccessoriesText-width">
          No Images Found.
        </p>
      )}
    </div>
  </Spin>
</div>


      </Spin>
    </div>
  );
};

export default ReferenceImages;
