import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./css/CustomerTable.css";

const CustomerTable = ({ customers, services, purchaseHistoryData, orderHistory,  wardrobeImagesList,  closeTable, activeFilterType }) => {
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);

  const limitedData =
  activeFilterType === "customer"
  ? customers.slice(0, 10)
  : activeFilterType === "purchaseHistory"
  ? purchaseHistoryData.slice(0, 8)
  : activeFilterType === "orderHistory"
  ? orderHistory.slice(0, 8)
  : activeFilterType === "wardrobeImages"
  ? wardrobeImagesList.slice(0, 8)  // <-- Added wardrobeImagesList condition
  : services.slice(0, 6);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const parsePaymentDetails = (paymentDetails) => {
    try {
      if (typeof paymentDetails === "string") {
        return JSON.parse(paymentDetails);
      }
      return paymentDetails;
    } catch (error) {
      console.error("Error parsing payment details:", error);
      return null;
    }
  };
  

  const mapStatusToText = (STATUS) => {
    switch (STATUS) {
      case 1:
        return "Active";
      case 4:
        return "Closed";
      default:
        return STATUS;
    }
  };

  const prepareDataForExport = () => {
    const data =
    activeFilterType === "customer"
    ? customers
    : activeFilterType === "purchaseHistory"
    ? purchaseHistoryData
    : activeFilterType === "orderHistory"
    ? orderHistory
    : activeFilterType === "wardrobeImages"
    ? wardrobeImagesList  // <-- Added wardrobeImagesList condition
    : services;


    if (!data || data.length === 0) return [];

    const headers = Object.keys(data[0]);

    return data.map((item) =>
      headers.map((header) =>
        header === "status" ? mapStatusToText(item[header]) : item[header]
      )
    );
  };

  const exportToExcel = () => {
    const data =
    activeFilterType === "customer"
    ? customers
    : activeFilterType === "purchaseHistory"
    ? purchaseHistoryData
    : activeFilterType === "orderHistory"
    ? orderHistory
    : activeFilterType === "wardrobeImages"
    ? wardrobeImagesList  // <-- Added wardrobeImagesList condition
    : services;

    if (!data || data.length === 0) return alert("No data to export!");

    const headers = Object.keys(data[0]);
    const ws = XLSX.utils.aoa_to_sheet([headers, ...prepareDataForExport()]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");

    const fileName = `${activeFilterType}_Report.xlsx`;
    XLSX.writeFile(wb, fileName);
    setShowDownloadOptions(false);
  };

  const exportToPDF = () => {
    const data =
    activeFilterType === "customer"
    ? customers
    : activeFilterType === "purchaseHistory"
    ? purchaseHistoryData
    : activeFilterType === "orderHistory"
    ? orderHistory
    : activeFilterType === "wardrobeImages"
    ? wardrobeImagesList  // <-- Added wardrobeImagesList condition
    : services;

    if (!data || data.length === 0) return alert("No data to export!");

    const headers = [["#", ...Object.keys(data[0])]];
    const doc = new jsPDF();
    const reportTitle = activeFilterType.replace(/([A-Z])/g, " $1").trim() + " Report";
    doc.text(reportTitle, 14, 15);

    doc.autoTable({
      head: headers,
      body: data.map((row, index) => [index + 1, ...Object.values(row)]),
      startY: 30,
      margin: { top: 20 },
    });

    const fileName = `${activeFilterType}_Report.pdf`;
    doc.save(fileName);
    setShowDownloadOptions(false);
  };

  useEffect(() => {
    console.log("Customers Data: ", customers);
    console.log("Services Data: ", services);
    console.log("Purchase History Data: ", purchaseHistoryData);
    console.log("Order History Data: ", orderHistory);
  }, [customers, services, purchaseHistoryData, orderHistory, wardrobeImagesList]);

  return (
    <>
      <div className="customer-table-overlay" onClick={closeTable}></div>
      <div className="customer-table">
        <button className="close-button" onClick={closeTable}>X</button>
        <div className="customer-table-header">
          <h3>
            {activeFilterType === "customer"
              ? "Customer List"
              : activeFilterType === "purchaseHistory"
              ? "Purchase History List"
              : activeFilterType === "orderHistory"
              ? "Order History List"
              : activeFilterType === "wardrobeImages"
              ? "Wardrobe Images List"
              : "Service List"}
          </h3>
          <button className="download-button" onClick={() => setShowDownloadOptions(true)}>Download Report</button>
        </div>

        {showDownloadOptions && (
          <div className="download-options">
            <button onClick={exportToExcel}>Download as Excel</button>
          </div>
        )}

        <div className="table-container">
          {limitedData.length > 0 ? (
            <>
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    {Object.keys(limitedData[0] || {}).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {limitedData.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {Object.keys(row).map((key, i) => (
                        <td key={i}>
                          {key === "STATUS" && activeFilterType === "customer"
                            ? row[key] === "9"
                              ? "Account Deleted"
                              : row[key] === "1"
                              ? "In Use"
                              : row[key] === "0"
                              ? "Not Filled Questions"
                              : row[key]
                            : key === "STATUS"
                            ? mapStatusToText(row[key])
                            : key === "SERVICE_ID"
                            ? row[key] === 1
                              ? "Shopping Assistant"
                              : row[key] === 2
                              ? "Wardrobe Assistant"
                              : row[key] === 3
                              ? "Wedding Assistant"
                              : row[key] === 4
                              ? "Capsule Wardrobe"
                              : row[key]
                            : ["ENROLLEDDATE", "START_DATE", "END_DATE"].includes(key)
                            ? formatDate(row[key])
                            : row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className="download-note">For more data, please download the full report.</p>
            </>
          ) : (
            <p>No data available to display</p>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerTable;

