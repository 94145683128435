import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Button, message, Flex, Input, Form } from "antd";
import { InboxOutlined, PictureOutlined } from "@ant-design/icons";
import CollageImageData from "../cards/CollageImageData";
import "./collage.css";
import "./css/Collage.css";
import { AppstoreOutlined, AppstoreFilled } from "@ant-design/icons";
import config from "../../config";
const Collage = ({
  handleSingleCollage,
  sampleImages,
  defaultFrameSize,
  SelectUserData,
  uploadCreatedCollage,
  UserWardrobeImagesData,
  uploadDragImageList,
  SendinputText,
  SendImageLinks,
  sendfileDataList,
  editItem,
  optionsList,
  onTabChange
}) => {
  const [fileList, setFileList] = useState([]);
  const [fileDataList, setFileDataList] = useState([]);
  const [frameSize, setFrameSize] = useState(defaultFrameSize || 5);
  const [images, setImages] = useState([]);
  const [disableButton, setDisableButton] = useState(true);
  const [inputText, setInputText] = useState(""); // Track input text
  const [inputError, setInputError] = useState(false);
  const canvasRef = useRef(null);
  const [form] = Form.useForm();
  const [selectedTemplate, setSelectedTemplate] = useState(5);
  // const [selectedTab, setSelectedTab] = useState("Mehandi"); 
  const [selectedTab, setSelectedTab] = useState(""); 

  const [inputValue, setInputValue] = useState("");

  const drawImagesOnCanvas = async (images) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    // Clear canvas before drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set canvas size
    const maxWidth = Math.max(...images.map((img) => img.x + img.width));
    const maxHeight = Math.max(...images.map((img) => img.y + img.height));
    canvas.width = maxWidth + 20; // Add extra space for border
    canvas.height = maxHeight + 20; // Add extra space for border

    // Draw images
    const imagePromises = images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = image.src;
        img.onload = () => {
          // Calculate aspect ratio to simulate "objectFit: contain"
          const aspectRatio = img.width / img.height;
          let drawWidth, drawHeight;

          if (image.width / image.height > aspectRatio) {
            drawHeight = image.height;
            drawWidth = image.height * aspectRatio;
          } else {
            drawWidth = image.width;
            drawHeight = image.width / aspectRatio;
          }

          // Center the image in its container
          const offsetX = image.x + 10 + (image.width - drawWidth) / 2;
          const offsetY = image.y + 10 + (image.height - drawHeight) / 2;

          // Draw image on the canvas
          ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

          resolve();
        };
        img.onerror = (error) => {
          console.error("Error loading image:", error);
          reject(error);
        };
      });
    });

    await Promise.all(imagePromises);

    // Draw border with border radius
    const borderRadius = 18; // Radius for rounded corners
    ctx.lineWidth = 1; // Border thickness
    ctx.strokeStyle = "black";
    ctx.beginPath();
    ctx.moveTo(borderRadius, 0);
    ctx.lineTo(canvas.width - borderRadius, 0);
    ctx.arcTo(canvas.width, 0, canvas.width, borderRadius, borderRadius);
    ctx.lineTo(canvas.width, canvas.height - borderRadius);
    ctx.arcTo(
      canvas.width,
      canvas.height,
      canvas.width - borderRadius,
      canvas.height,
      borderRadius
    );
    ctx.lineTo(borderRadius, canvas.height);
    ctx.arcTo(0, canvas.height, 0, canvas.height - borderRadius, borderRadius);
    ctx.lineTo(0, borderRadius);
    ctx.arcTo(0, 0, borderRadius, 0, borderRadius);
    ctx.closePath();
    ctx.stroke();
  };
//tab 
const handleTabChange = (tabName) => {
  setSelectedTab(tabName); // Update local state
  if (onTabChange) {
    onTabChange(tabName); // Pass the tabName to parent
  }
};


  const uploadcollage = async () => {
    try {
      const canvas = canvasRef.current;
      console.log("Canvas drawing complete", canvas);
      const blob = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Blob creation failed"));
          }
        }, "image/png");
      });

      if (blob) {
        const arrayBuffer = await blob.arrayBuffer();
        const value = new Uint8Array(arrayBuffer);

        const format = new Intl.DateTimeFormat("en", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        const date = format.format(new Date()).replace(/[^0-9]/g, "_");

        // Create a file from the blob
        const file = new File([value], `Collage_${date}.png`, {
          type: "image/png",
        });

        console.log("File created:", file);
        await uploadCreatedCollage(
          file,
          form.getFieldValue("inputText"),
          fileDataList,
          fileList
        );
        console.log(fileList, "filelistnbvkj,bj");
        console.log(fileDataList, "filelistnbvkj,bj2");
       

        await uploadDragImageList(fileDataList);
        SendinputText(form.getFieldValue("inputText"));

        console.log(form.getFieldValue("inputText"), ".....input text");
        sendfileDataList(fileDataList);
        setInputText(""); // Clear the input field
        setInputError(false);
        SendImageLinks(fileList);
        setDisableButton(false);
        console.log("Upload complete.,");

        form.setFieldsValue({ inputText: "" });
        handleClear();
        setFileList([]);

        setDisableButton(false);
      }
    } catch (error) {
      console.error("Error during collage upload:", error);
    }
  };
  // console.log(editItem);

  // useEffect(() => {
  //   setFileList(new Array(frameSize).fill(null));
  // }, [frameSize]);
  // useEffect(() => {
  //   checkButtonStatus(fileList, inputValue);
  // }, [selectedTemplate]);

  useEffect(() => {
    console.log("Options List in collage:", optionsList); 
    if (optionsList && optionsList.length > 0) {
      setSelectedTab(optionsList[0]); // Set the first option as the default selected tab
    }
    if (editItem) {
      // Only proceed if editItem is defined
      form.setFieldsValue({ inputText: editItem.COLLAGE_DESCRIPTION });
  
      const scrapeData = editItem.SCRAPE_DATA
        ? JSON.parse(editItem.SCRAPE_DATA)
        : [];
  
      console.log(scrapeData, "scrapeData");
      console.log(editItem, "editItem");
  
      let imageList = [];
      if (editItem.IMAGE_LIST) {
        try {
          // Parse the IMAGE_LIST as JSON to get the array of escaped strings
          const parsedList = JSON.parse(editItem.IMAGE_LIST);
  
          // Remove additional quotes around each URL
          imageList = parsedList.map((url) => url.replace(/(^\\")|(\\"$)/g, ""));
          console.log(imageList, "imagelist");
        } catch (error) {
          console.error("Error parsing IMAGE_LIST:", error);
        }
      }
  
      handleEdit(imageList);
      handleTemplateSelection(defaultFrameSize)
    }
  }, [editItem]);

  // useEffect(() => {
  //   form.setFieldsValue({ inputText: editItem.COLLAGE_DESCRIPTION });

  //   const scrapeData = editItem.SCRAPE_DATA
  //     ? JSON.parse(editItem.SCRAPE_DATA)
  //     : [];

  //     console.log(scrapeData, "scrapeData");
  // //   const scrapeData = editItem.IMAGE_LIST
  // // ? editItem.IMAGE_LIST.map((url) => JSON.parse(url)) // Parse each escaped URL string
  // // : [];
  // let imageList = [];
  // if (editItem.IMAGE_LIST) {
  //   try {
  //     // Parse the IMAGE_LIST as JSON to get the array of escaped strings
  //     const parsedList = JSON.parse(editItem.IMAGE_LIST);

  //     // Remove additional quotes around each URL
    
  //     imageList = parsedList.map((url) => url.replace(/(^\\")|(\\"$)/g, ""));
  //     console.log(imageList, "imagelist");
  //   } catch (error) {
  //     console.error("Error parsing IMAGE_LIST:", error);
  //   }
  // }

  //   handleEdit(imageList);
  //   // handleEdit(imageList);
  // }, [editItem]);

  const handleClear = () => {
    form.setFieldsValue({ inputText: "" });
    setInputError(false); // Reset error state
  };
  const validateForm = (files, input) => {
    const allImagesFilled = files.every((file) => file !== null); // All image slots filled
    const isInputFilled = input && input.length > 0; // Input is not empty
    setDisableButton(!(allImagesFilled && isInputFilled)); // Enable button if both are valid
  };

  const handleDrop = async (index, src, parsedData) => {
    
    // Create a copy of the existing file list
    const newFileList = [...fileList];
    const newFileDataList = [...fileDataList]; // Copy for storing parsedData or src

    // Update newFileList with the src at the specified index
    newFileList[index] = src;

    // Store parsedData if it's not null, otherwise store src
    newFileDataList[index] = parsedData !== null ? parsedData : src;
    console.log(newFileDataList, "newFileDataList................");
    // Update state for both fileList and fileDataList
    await setFileList(newFileList);
    await setFileDataList(newFileDataList);

    console.log(newFileList[index], "Updated file list....");
    console.log(newFileDataList[index], "Updated file data list....");

    // Perform additional actions based on updated newFileList
    checkButtonStatus(newFileList, inputValue,selectedTemplate);
    validateForm(newFileList, form.getFieldValue("inputText"));
  };

  const handleEdit = async (imageList) => {
    // Clean the imageList URLs by removing extra quotes
    const cleanedImageList = imageList.map((src) => src.replace(/(^"|"$)/g, ''));
  
    // Create new arrays for fileList and fileDataList
    const newFileList = [...fileList];
    const newFileDataList = [...fileDataList];
  
    // Update the newFileList and newFileDataList arrays based on the cleaned URLs
    cleanedImageList.forEach((src, index) => {
      newFileList[index] = src;
      newFileDataList[index] = src;
    });
  
    console.log(newFileDataList, "Updated newFileDataList with all images....");
  
    // Update the state with the new arrays
    await setFileList(newFileList);
    await setFileDataList(newFileDataList);
  
    console.log(newFileList, "Updated fileList with all images....");
    console.log(newFileDataList, "Updated fileDataList with all images....");
  
    // Perform additional actions based on updated newFileList
    checkButtonStatus(newFileList, inputValue, selectedTemplate);
    validateForm(newFileList, form.getFieldValue("inputText"));
  };
  
  // const handleEdit = async (imageList) => {
  //   const newFileList = [...fileList];
  //   const newFileDataList = [...fileDataList];

  //   // Loop through the imageList array of URLs and update the lists based on the index
  //   imageList.forEach((src, index) => {
  //     // Update newFileList and newFileDataList based on each image's index
  //     newFileList[index] = src;
  //     newFileDataList[index] = src;
  //   });

  //   console.log(newFileDataList, "Updated newFileDataList with all images....");

  //   // Update state for both fileList and fileDataList
  //   await setFileList(newFileList);
  //   await setFileDataList(newFileDataList);

  //   console.log(newFileList, "Updated fileList with all images....");
  //   console.log(newFileDataList, "Updated fileDataList with all images....");

  //   // Perform additional actions based on updated newFileList
  //   checkButtonStatus(newFileList, inputValue,selectedTemplate);
  //   validateForm(newFileList, form.getFieldValue("inputText"));
  // };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };
  const handleConvert = async () => {
    setDisableButton(true);
    const imagefiledata = fileList.slice(0, selectedTemplate);
    const imageUrls = imagefiledata.filter((src) => src !== null);
    if (imageUrls.length < selectedTemplate) {
      message.error("Please add images to the placeholders.");
      setDisableButton(false);
      return;
    }
    console.log(form);
    if (!form.getFieldValue("inputText").trim()) {
      setInputError("Collage name is required"); // Set error message
      return; // Prevent further execution
    }
    setInputError(""); // Reset error if input is valid
    // Continue with your collage creation logic

    const loadedImages = await Promise.all(imageUrls.map(loadImage));
    // const newImages = loadedImages.map((img, index) => ({
    //   src: img.src,
    //   x: getImagePositions(frameSize)[index].x,
    //   y: getImagePositions(frameSize)[index].y,
    //   width: getImagePositions(frameSize)[index].width,
    //   height: getImagePositions(frameSize)[index].height,
    // }));
    const newImages = loadedImages.map((img, index) => ({
      src: img.src,
      x: getImagePositions(frameSize)[index].x,
      y: getImagePositions(frameSize)[index].y,
      width: getImagePositions(frameSize)[index].width,
      height: getImagePositions(frameSize)[index].height,
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        objectFit: "contain",
      },
    }));

    setImages(newImages);
    await drawImagesOnCanvas(newImages);
    await uploadcollage(newImages);
  };
  const handleTemplateSelection = async (template) => {
    setFrameSize(template);
    await checkButtonStatus(
      fileList,
      form.getFieldValue("inputText"),
      template
    );
    setSelectedTemplate(template);
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    // console.log(text);
    // setInputText(text);
    form.setFieldsValue({ inputText: text });
    // console.log(form.getFieldValue("inputText"));
    setInputError(false); // Clear error when user starts typing
    // validateForm(fileList, text);
    checkButtonStatus(fileList, text,selectedTemplate);
  };

  const checkButtonStatus = (images, inputText, template) => {
    const imagefiledata = images.slice(0, template);

    const allImagesFilled =
      imagefiledata.filter((src) => src !== null).length === template
       // Check if all images are filled
    console.log(
      selectedTemplate,images, inputText, template,'sdjiodsjo',allImagesFilled , template , inputText
    );
    if (allImagesFilled  && inputText) {
      setDisableButton(false); // Enable the button
    } else {
      setDisableButton(true); // Disable the button
    }
  };

  const getImagePositions = (size) => {
    if (size === 4) {
      return [
        { x: 0, y: 0, width: 195, height: 300 },

        { x: 200, y: 0, width: 195, height: 300 },

        { x: 400, y: 0, width: 147, height: 148 },
        { x: 400, y: 152, width: 147, height: 148 },
        // { x: 400, y: 200, width: 147, height: 100 },
      ];
    } else if (size === 5) {
      return [
        { x: 0, y: 0, width: 195, height: 300 },

        { x: 200, y: 0, width: 195, height: 300 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },
        { x: 400, y: 200, width: 147, height: 100 },
      ];
    } else if (size === 6) {
      return [
        { x: 0, y: 0, width: 195, height: 300 },

        { x: 200, y: 0, width: 195, height: 145 },
        { x: 200, y: 150, width: 195, height: 150 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },
        { x: 400, y: 200, width: 147, height: 100 },
      ];
    } else if (size === 7) {
      return [
        { x: 0, y: 0, width: 195, height: 145 },
        { x: 0, y: 150, width: 195, height: 150 },

        { x: 200, y: 0, width: 195, height: 145 },
        { x: 200, y: 150, width: 195, height: 150 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },
        { x: 400, y: 200, width: 147, height: 100 },
      ];
    } else if (size === 8) {
      return [
        { x: 0, y: 0, width: 195, height: 145 },
        { x: 0, y: 150, width: 195, height: 145 },

        { x: 200, y: 0, width: 195, height: 95 },
        { x: 200, y: 100, width: 195, height: 95 },
        { x: 200, y: 200, width: 195, height: 95 },

        { x: 400, y: 0, width: 147, height: 95 },
        { x: 400, y: 100, width: 147, height: 95 },
        { x: 400, y: 200, width: 147, height: 95 },
      ];
    }
  };

  const DisplaygetImagePositions = (size) => {
    if (size === 4) {
      return [
        { x: "0%", y: "0%", width: "32%", height: "100%" },
        { x: "34%", y: "0%", width: "32%", height: "100%" },
        { x: "68%", y: "0%", width: "22%", height: "48%" },
        { x: "68%", y: "52%", width: "22%", height: "48%" },
        // { x: "68%", y: "70%", width: "22%", height: "30%" },
      ];
    } else if (size === 5) {
      return [
        { x: "0%", y: "0%", width: "32%", height: "100%" },
        { x: "34%", y: "0%", width: "32%", height: "100%" },
        { x: "68%", y: "0%", width: "22%", height: "30%" },
        { x: "68%", y: "35%", width: "22%", height: "30%" },
        { x: "68%", y: "70%", width: "22%", height: "30%" },
      ];
    } else if (size === 6) {
      return [
        { x: "0%", y: "0%", width: "32%", height: "100%" },

        { x: "34%", y: "0%", width: "32%", height: "49%" },
        { x: "34%", y: "51%", width: "32%", height: "49%" },

        { x: "68%", y: "0%", width: "22%", height: "30%" },
        { x: "68%", y: "35%", width: "22%", height: "30%" },
        { x: "68%", y: "70%", width: "22%", height: "30%" },
      ];
    } else if (size === 7) {
      return [
        { x: "0%", y: "0%", width: "32%", height: "49%" },
        { x: "0%", y: "51%", width: "32%", height: "49%" },

        { x: "34%", y: "0%", width: "32%", height: "49%" },
        { x: "34%", y: "51%", width: "32%", height: "49%" },

        { x: "68%", y: "0%", width: "22%", height: "30%" },
        { x: "68%", y: "35%", width: "22%", height: "30%" },
        { x: "68%", y: "70%", width: "22%", height: "30%" },
      ];
    } else if (size === 8) {
      return [
        { x: "0%", y: "0%", width: "32%", height: "49%" },
        { x: "0%", y: "51%", width: "32%", height: "49%" },

        { x: "34%", y: "0%", width: "28%", height: "30%" },
        { x: "34%", y: "35%", width: "28%", height: "30%" },
        { x: "34%", y: "70%", width: "28%", height: "30%" },

        { x: "64%", y: "0%", width: "28%", height: "30%" },
        { x: "64%", y: "35%", width: "28%", height: "30%" },
        { x: "64%", y: "70%", width: "28%", height: "30%" },
      ];
    }
  };

  const getBorderStyle = (template) => {
    return selectedTemplate === template
      ? { border: "2px solid #1890ff" } // Highlight the selected div
      : { border: "2px solid transparent" }; // Default transparent border
  };
  return (

    
    <div
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      className="collagecreate"
    >
  {/* <div style={{ display: "flex", flexDirection: "column", gap: "20px" }} className="collagecreate"> */}
  <div
    style={{ display: "flex", flexDirection: "row", gap: "20px" }}
    className="collagecreate"
  >
    <div
      className="tab-buttons"
      style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
    >
      {optionsList && optionsList.length > 0 &&
        optionsList.map((tabName, index) => (
          <Button
            key={index} // Using index as the key for mapping
            style={{
              backgroundColor: selectedTab === tabName ? "#31363f" : "#ffffff",
              color: selectedTab === tabName ? "#ffffff" : "#31363f",
              border: "1px solid #31363f",
            }}
            onClick={() => handleTabChange(tabName)} // Update content based on the tab clicked
          >
            {tabName}
          </Button>
        ))}
    </div>
  </div>

      <div className="userProfileMain">
        <h1 className="heading"> Create Collage </h1>
      </div>
      <p style={{ margin: "0" }}>1. Choose a collage template.</p>
      <p style={{ margin: "0" }}>
        2. Drag and drop images into the template to create collages.
      </p>
      <p style={{ margin: "0" }}>
        3. Select a finished collage to send to the customer.
      </p>
      <div style={{ display: "flex", gap: "20px", height: "75.5vh" }}>
        <div style={{ flex: 1, overflow: "scroll", overflowX: "hidden" }}>
          <Row
            gutter={[16, 16]}
            style={{
              margin: "0 10px ",
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
              <CollageImageData SelectUserData={SelectUserData} />
            </div>
          </Row>
        </div>

        <div
          style={{ flex: 1, border: "2px solid #d9d9d9", borderRadius: "8px" }}
        >
          <div className="Shopping-Collage-Header">
            <div className="Wardrobesubtabs">
              <p>Select a template</p>{" "}
              <Flex
                horizontal
                className="Shopping-Collage-Header"
                style={{ margin: "2% 0 0 0" }}
              >
                <div className="Template-Options waddropselectfarme">
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(4)}
                  >
                    <div
                      className="Scralink-Bottom-Template"
                      style={getBorderStyle(4)}
                    >
                      {/* <AppstoreFilled /> */}
                      {selectedTemplate === 4 ? (
                        <AppstoreFilled />
                      ) : (
                        <AppstoreOutlined />
                      )}
                    </div>
                    <p>4 Images</p>
                  </Flex>
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(5)}
                  >
                    <div
                      className="Scralink-Bottom-Template"
                      style={getBorderStyle(5)}
                    >
                      {/* <AppstoreFilled /> */}
                      {selectedTemplate === 5 ? (
                        <AppstoreFilled />
                      ) : (
                        <AppstoreOutlined />
                      )}
                    </div>
                    <p>5 Images</p>
                  </Flex>
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(6)}
                  >
                    <div
                      className="Scralink-Bottom-Template"
                      style={getBorderStyle(6)}
                    >
                      {selectedTemplate === 6 ? (
                        <AppstoreFilled />
                      ) : (
                        <AppstoreOutlined />
                      )}
                    </div>
                    <p>6 Images</p>
                  </Flex>
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(7)}
                  >
                    <div
                      className="Scralink-Bottom-Template"
                      style={getBorderStyle(7)}
                    >
                      {selectedTemplate === 7 ? (
                        <AppstoreFilled />
                      ) : (
                        <AppstoreOutlined />
                      )}
                    </div>
                    <p>7 Images</p>
                  </Flex>
                  <Flex
                    vertical
                    align="center"
                    onClick={() => handleTemplateSelection(8)}
                  >
                    <div
                      className="Scralink-Bottom-Template"
                      style={getBorderStyle(8)}
                    >
                      {selectedTemplate === 8 ? (
                        <AppstoreFilled />
                      ) : (
                        <AppstoreOutlined />
                      )}
                    </div>
                    <p>8 Images</p>
                  </Flex>
                </div>
              </Flex>
            </div>
          </div>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <PictureOutlined style={{ marginRight: "10px" }} />
                  Drag and Drop Images Here
                </div>
                <Button
                  className="button"
                  disabled={disableButton}
                  onClick={() => handleConvert()}
                >
                  {/* {collagecreationtype} */}
                  Generate Collage
                </Button>
              </div>
            }
            style={{
              marginBottom: "20px",
              position: "relative",
              border: "none",
              height: "59%",
            }} // Adjust height based on the total collage height
          >
            <div
              style={{
                position: "relative",
                width: "111%",
                height: "82%",
              }}
            >
              <div className="container">
                {DisplaygetImagePositions(frameSize).map((pos, index) => (
                  <div
                    key={index}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      console.log("onDrop triggered for index:", index);
                      console.log("Available data types:", e.dataTransfer.types);
                      const availableTypes = Array.from(e.dataTransfer.types);
                      console.log("Available types:", availableTypes);
                      let dataHandled = false;
                      
                      if (availableTypes.includes("text/uri-list")) {
                        const uriList = e.dataTransfer.getData("text/uri-list");
                        console.log("URI List:", uriList);
                    
                        dataHandled = true;
                      }

                      if (availableTypes.includes("text/html")) {
                        const htmlContent = e.dataTransfer.getData("text/html");
                        console.log("HTML Content:", htmlContent);
                        const imgMatch = htmlContent.match(/<img[^>]+src="([^">]+)"/);
                        const imgSrc = imgMatch ? imgMatch[1] : null;
                        if (imgSrc) {
                          console.log("Extracted image source:", imgSrc);
                    
                        }
                      }
                      const data = e.dataTransfer.getData("application/json");
                      const wardrobe2 = e.dataTransfer.getData("text/plain");
                      console.log("Received dataTransfer:", { data, wardrobe2 });
                      
                      try {
                        const parsedData = JSON.parse(data);
                        console.log("Parsed data:", parsedData);
                    
                        const imgpath =
                          Array.isArray(parsedData?.imageURL) && parsedData.imageURL.length > 0
                            ? parsedData.imageURL[0]
                            : parsedData.imageURL;
                        console.log("Image path determined:", imgpath);
                    
                        const imageURL = `${config.imageUrl}/${imgpath}`;
                        console.log("Final image URL:", imageURL);
                    
                        handleDrop(index, imageURL, parsedData);
                      } catch (error) {
                        console.error("Error parsing data:", error);
                        const htmlContent = e.dataTransfer.getData("text/html");
                        console.log("HTML Content:", htmlContent);
                        const imgMatch = htmlContent.match(/<img[^>]+src="([^">]+)"/);
                        const imgSrc = imgMatch ? imgMatch[1] : null;
                    
                        if (wardrobe2) {
                          console.log("Using wardrobe2:", wardrobe2);
                          handleDrop(index, wardrobe2, null);
                        } else if (data) {
                          console.log("Using raw data:", data);
                          handleDrop(index, data, null);
                        } else  if (imgSrc) {
                          console.warn("Unsupported data types:", availableTypes);
                          handleDrop(index, imgSrc, null);
                        }
                        else {
                          console.log("Invalid data received:", { data, wardrobe2 });
                          handleDrop(index, null, null);
                        }
                      }
                    }}
                    
                    style={{
                      position: "absolute",
                      top: pos.y,
                      left: pos.x,
                      width: pos.width,
                      height: pos.height,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: fileList[index] ? "none" : "#fafafa",
                      overflow: "hidden",
                      border: "2px solid #d9d9d9",
                      padding: "7px",
                      borderRadius: "4px",
                    }}
                  >
                    {fileList[index] ? (
                      <img
                        src={fileList[index]}
                        alt={`placeholder-${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <InboxOutlined
                        style={{ fontSize: "24px", color: "#bfbfbf" }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <Form form={form} style={{ marginTop: "3%" }}>
                <Form.Item
                  name="inputText"
                  rules={[
                    { required: true, message: "Please input the Description !" },
                  ]}
                >
                  <Input
                    placeholder="Enter Collage Description"
                    value={form.getFieldValue("inputText")} // Bind value to form's field value
                    onChange={handleInputChange}
                    status={inputError ? "error" : ""}
                    // onDrop={(e) => e.preventDefault()} // Disable drop
                    // onDragOver={(e) => e.preventDefault()}
                  />
                </Form.Item>
              </Form>
            </div>
{/*             
  {SelectUserData?.SERVICE_SHORT_CODE === '3' ? (
    <div>
    <Form form={form} style={{ marginTop: "3%" }}>
      <Form.Item
        name="inputText"
        rules={[{ required: true, message: "Please input the Event Description !" }]}
      >
        <Input
          placeholder="Enter the Event Description"
          value={form.getFieldValue("inputText")} // Bind value to form's field value
          onChange={handleInputChange}
          status={inputError ? "error" : ""}
          // onDrop={(e) => e.preventDefault()} // Disable drop
          // onDragOver={(e) => e.preventDefault()}
        />
      </Form.Item>
    </Form>
    </div>
  ) : null} */}


          </Card>
        </div>
      </div>

      <div>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default Collage;
