import React, { useEffect, useState, createContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/DashboardLayout";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./Components/firebase/firebaseUtiles";

import { Button, Divider, notification } from "antd";
import { Prev } from "react-bootstrap/lib/Pagination";

export const NotificationContext = createContext();


const App = () => {
  const [api, contextHolder] = notification.useNotification();
  const [notificationCount, setNotificationCount] = useState(0);
  const [refreshData, setRefreshData] = useState(false);


  useEffect(() => {
    // Listen for new messages
    onMessage(messaging, (payload) => {
      console.log("payload in notifications", payload)
      api.info({
        message: payload?.notification?.title,
        description: payload?.notification?.body,
      });
      
      // Increment the notification count
      setNotificationCount((prev) => prev + 1);
      setRefreshData((prev) => !prev);
    });
  }, [api]);

  // Handler to clear the notification count
  const clearNotificationCount = () => {
    setNotificationCount(0);
  };

  return (
    <NotificationContext.Provider value={{ refreshData}} >
       <Router>
      {contextHolder}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard/*"
          element={
            <Dashboard
              notificationCount={notificationCount}
              clearNotificationCount={clearNotificationCount}
            />
          }
        />
      </Routes>
    </Router>
    </NotificationContext.Provider>
  );
};

export default App;
