import React, { useState, useEffect } from "react";
import { Card, Statistic } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import axios from "axios";
import "./css/DashboardLayout.css";

const { Content } = Layout;

const AdminPanel = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersPerDay, setUsersPerDay] = useState(0);
  const staticUsers = 500;
  const usersPerDay1 = 400;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/admin/users-stats");
        setTotalUsers(response.data.totalUsers);
        setUsersPerDay(response.data.usersPerDay);
      } catch (error) {
        console.error("Error fetching user stats", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Content style={{ display: "flex", alignItems: "flex-start", padding: "24px" }}>
      <Card
        bordered={false}
        style={{
          width: 250,
          height: 250,
          textAlign: "center",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Statistic title="Total Users" value={staticUsers} prefix={<UserOutlined />} />
        <Statistic title="Users Per Day" value={usersPerDay1} prefix={<UserOutlined />} style={{ marginTop: 20 }} />
      </Card>
    </Content>
  );
};

export default AdminPanel;
