import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification, Radio, Flex } from "antd";
import { CameraOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../config";
import "./Profile.css";
import Profileimage from "../../images/profile.jpg";
import { Spin } from "antd";
import { userReturn } from "../../utils/encryption";
const ProfileForm = () => {
  const [form] = Form.useForm();
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstname: LoginUser.FIRST_NAME || "",
    lastname: LoginUser.LAST_NAME || "",
    dateofbirth: LoginUser?.DATE_OF_BIRTH
      ? convertFromISO(LoginUser?.DATE_OF_BIRTH)
      : LoginUser?.AGE && !isNaN(LoginUser?.AGE)
      ? getDateOfBirth(LoginUser?.AGE)
      : "",
    weight: LoginUser.WEIGHT || "",
    height: LoginUser.HEIGHT || "",
    gender: LoginUser.GENDER || "Male",
    userPhoto: [],
  });

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(
    LoginUser?.USER_PHOTO
      ? `${config.imageUrl}${LoginUser?.USER_PHOTO}`
      : Profileimage
  );
  const [imageFile, setImageFile] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setImageFile(file);
      };
      reader.readAsDataURL(file);
    }
  };
  function convertFromISO(isoDateStr) {
    const date = new Date(isoDateStr);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  function getDateOfBirth(age) {
    const today = new Date();
    const birthYear = today.getFullYear() - age;
    return new Date(today.setFullYear(birthYear)).toISOString().split("T")[0];
  }

  const calculateAge = (dob) => {
    const age = new Date().getFullYear() - new Date(dob).getFullYear();
    return age;
  };

  const onFinish = async (values) => {
    setLoading(true);
  
    try {
      const formData = new FormData();
      if (imageFile) {
        formData.append("picture", imageFile);
      }
      formData.append(
        "data",
        JSON.stringify({
          FIRST_NAME: values.firstname,
          LAST_NAME: values.lastname,
          STYLIST_ID: LoginUser.USER_ID,
          AGE: calculateAge(values.dateofbirth),
          WEIGHT: values.weight,
          HEIGHT: values.height,
          GENDER: values.gender,
          USER_ID: LoginUser.USER_ID,
          DOB: values.dateofbirth,
        })
      );

      const response = await axios.post(
        `${config.baseURL}editStylistProfile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );

      if (response.data.message === "success") {
        sessionStorage.setItem(
          "LoginUser",
          JSON.stringify(response?.data?.userdata?.[0])
        );
        
        notification.success({
          message: "Updated Success",
          description: `Profile has been updated successfully.`,
        });

        setIsEditing(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      
      console.error("Error saving profile:", error);
    }
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onCancel = () => {
    form.resetFields();
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "firstname" || name === "lastname") {
      const filteredValue = value.replace(/[^A-Za-z]/g, "");
      setInitialValues({
        ...initialValues,
        [name]: filteredValue,
      });
      form.setFieldsValue({ [name]: filteredValue });
    } else {
      setInitialValues({
        ...initialValues,
        [name]: value,
      });
    }
  };

  return (
    <Row align="middle" className="Profile-Content">
      <Col span={22}>
        <Flex align="flex-end" className="Profile-Header-Logo">
          <Flex align="center" gap={30}>
            <div style={{ position: "relative" }}>
              <img
                src={image}
                alt="Profile"
                width="130px"
                height="130px"
                style={{ borderRadius: "50%", cursor: "pointer" }}
                onClick={handleImageClick}
              />
              <input
                id="file-input"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
              {/* Camera Icon */}
              {isEditing && (
                <CameraOutlined
                  onClick={() => document.getElementById("file-input").click()}
                  style={{
                    top: "38px",
                    position: "absolute",
                    left: "90px",
                    opacity: "70%",
                  }}
                />
              )}
            </div>
            <div className="Profile-Header-Buttons">
              {!isEditing ? (
                <Flex>
                  <Button onClick={onEdit}>Edit</Button>
                </Flex>
              ) : (
                <Flex>
                  <Flex gap={20}>
                    <Button htmlType="submit" form="profile_form">
                      Submit
                    </Button>
                    <Button onClick={onCancel} type="default">
                      Cancel
                    </Button>
                  </Flex>
                </Flex>
              )}
            </div>
          </Flex>
        </Flex>
        <Form
          form={form}
          name="profile_form"
          onFinish={onFinish}
          layout="vertical"
          initialValues={initialValues}
          disabled={!isEditing}
          encType="multipart/form-data"
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="firstname"
                label="First Name"
                rules={[
                  { required: true, message: "Please enter your first name" },
                ]}
              >
                <Input
                  placeholder="Enter your first name"
                  className="Profile-Form-Input"
                  name="firstname"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastname"
                label="Last Name"
                rules={[
                  { required: true, message: "Please enter your last name" },
                ]}
              >
                <Input
                  placeholder="Enter your last name"
                  className="Profile-Form-Input"
                  name="lastname"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="dateofbirth"
                label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please select your date of birth!",
                  },
                ]}
              >
                <Input
                  type="date"
                  className="Profile-Form-Input"
                  name="dateofbirth"
                  style={{ width: "100%" }}
                  value={initialValues.dateofbirth}
                  onChange={(e) => {
                    handleInputChange(e);
                    setInitialValues((prev) => ({
                      ...prev,
                      dateofbirth: e.target.value,
                    }));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="weight"
                label="Weight (kg)"
                rules={[
                  { required: true, message: "Please input your weight!" },
                ]}
              >
                <Input
                  type="number"
                  className="Profile-Form-Input"
                  placeholder="Enter your weight"
                  name="weight"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                name="height"
                label="Height (FT)"
                rules={[
                  { required: true, message: "Please input your height!" },
                ]}
              >
                <Input
                  type="number"
                  className="Profile-Form-Input"
                  placeholder="Enter your height"
                  name="height"
                  onChange={handleInputChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Gender" name="gender">
                <Radio.Group onChange={handleInputChange} name="gender">
                  <Radio value="Male">Male</Radio>
                  <Radio value="Female">Female</Radio>
                  <Radio value="Other">Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      {loading && (
        <div className="loadinginprofile">
          <Spin tip="Loading..." size="large" />
        </div>
      )}
    </Row>
  );
};

export default ProfileForm;
