import React from "react";
import { Card, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons"; // Import Ant Design icon
import "./NotificationCard.css"; // Import external CSS

const NotificationCard = ({
  serviceId,
  userName,
  timeAgo,
  title,
  body,
  notificationType,
  receiver,
}) => {
  return (
    <Card className={`notification-card`}>
      <div className="notification-header">
        <div className="notification-info">
          <span className={`notification-type info`}>{notificationType.trim()}</span>
          <span className="service-id">
            Service ID : <span className="service-id-value">{serviceId}</span>
          </span>
        </div>
        <div className="notification-meta">
          <span className="time-ago">{timeAgo}</span>
        </div>
      </div>
      <Divider className="divider" />
      <div className="notification-body">
        <span className="notification-title">{title}</span>
      </div>
      <div className="notification-content">
        <p>{body}</p>
      </div>
      {userName && (
        <div className="notification-header">
          <span className="user-name">User: {userName}</span>
          <div className="notification-header">
            <RightOutlined className="arrow-icon" />
            <RightOutlined className="arrow-icon" />
            <RightOutlined className="arrow-icon" />
          </div>
          {/* Ant Design icon for navigation */}
          <span className="user-name">Receiver: {receiver}</span>
        </div>
      )}
    </Card>
  );
};

export default NotificationCard;
