// import NodeRSA from 'node-rsa';


// const publicKey = `-----BEGIN PUBLIC KEY-----
// MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3fhQg1wiiW3LpcsgX0uB
// fRuxtBiRnsFKXiFPiygTeLpLQey3KvFT7vq1l/9Hrbuy3TgwmuhXcwyYBKcAZ71h
// 9GV/QUFI8iTOm+9aPglU+Tx/JRJFctMpUk9CfIZp56DYxvLSL/Ni62b6QgU8ySuJ
// nS/Z+McWlD0VKLFmpkpMbc9AvJTH+5lKAQ8uFozTISSPB4AObI2ki8TWverZJTGT
// FfhMiWkQ+IIreZXcSwU8IpTHMDPlh0i4r/ml4KAf7DikYBs41QSjQk8WJU+0hwNN
// LbauhA/NkIXQftSqd/nmEuIpX5lEAADK60tQLy+gTLz4YU6szrOgYZE3OEGIE+pC
// EwIDAQAB
// -----END PUBLIC KEY-----`;



// export const userReturn = (userId) => {
//   try {
//     const key = new NodeRSA();
//     key.importKey(publicKey, 'pkcs8-public'); // Import public key
//     key.setOptions({ encryptionScheme: 'pkcs1' }); // Set encryption scheme
    
//     const encryptedUserId = key.encrypt(userId, 'base64'); // Encrypt userId
//     console.log("Encrypted User ID:", encryptedUserId);

//     return encryptedUserId;
//   } catch (error) {
//     console.error("Encryption Error:", error);
//     return null;
//   }
// };


const forge = require('node-forge');

const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3fhQg1wiiW3LpcsgX0uB
fRuxtBiRnsFKXiFPiygTeLpLQey3KvFT7vq1l/9Hrbuy3TgwmuhXcwyYBKcAZ71h
9GV/QUFI8iTOm+9aPglU+Tx/JRJFctMpUk9CfIZp56DYxvLSL/Ni62b6QgU8ySuJ
nS/Z+McWlD0VKLFmpkpMbc9AvJTH+5lKAQ8uFozTISSPB4AObI2ki8TWverZJTGT
FfhMiWkQ+IIreZXcSwU8IpTHMDPlh0i4r/ml4KAf7DikYBs41QSjQk8WJU+0hwNN
LbauhA/NkIXQftSqd/nmEuIpX5lEAADK60tQLy+gTLz4YU6szrOgYZE3OEGIE+pC
EwIDAQAB
-----END PUBLIC KEY-----`;

export const userReturn = (userId) => {
  console.log(userId , "userID in userREturn");
  try {
    // Convert PEM public key to a Forge public key object
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

    // Convert userId to a string (RSA requires a string input)
    const userIdString = userId.toString();

    // Encrypt using RSA PKCS#1 v1.5 (matches backend's `RSA_PKCS1_PADDING`)
    const encryptedBytes = publicKey.encrypt(userIdString, "RSAES-PKCS1-v1_5");

    // Convert encrypted data to Base64
    const encryptedBase64 = forge.util.encode64(encryptedBytes);

    console.log("Encrypted User ID (Base64):", encryptedBase64);
    
    return encryptedBase64;
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
};
// Example usage:

