import React, { useState, useEffect } from "react";
import { DatePicker, Button, Typography, Row, Col, Spin, message } from "antd";
import axios from "axios";
import config from "../../config";
import CurrentServicesCard from "../cards/CurrentServicesCard";
import UserDisplayCard from "../cards/UserDisplayCard";
import { useNavigate } from "react-router-dom";
import "../../pages/css/UserProfile.css";
import { userReturn } from "../../utils/encryption";
import UserProfile from "../../pages/UserProfile";
function Servicehistory() {
  const [shoptype, setShopType] = useState("Shopping");
  const [showDetails, setshowDetails] = useState(true);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [UseData, setUserData] = useState([]);
  const [loading, setloading] = useState(true);
  const [selectviewuser, setselectviewuser] = useState({});
  const navigate = useNavigate();

  const getUserData = async (userId) => {
    const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

    const endpointUrl =
      LoginUser.ROLE === "0"
        ? "getClosedConnections"
        : "getClosedConnectionsStylist";

    try {
      const response = await axios.get(
        
        `${config.baseURL}${endpointUrl}/${LoginUser?.USER_ID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );

      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;
        console.log("reponsedatainservicehistory", data);
        console.log("...........");
        setUserData(data);
        setloading(false);
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching unassigned details:", error);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  const onClick = (data) => {
    console.log(data, "Onclick data ....");
    setselectviewuser(data);
    setshowDetails(false);
    navigate(`/dashboard/ServiceHistory/${data.USER_SERVICE_ID}`);
  };
  return (
    <>
      {showDetails ? (
        <div className="userProfileMain" style={{background:'white'}}>
          {LoginUser.ROLE === "0" ? (
            <h1 className="heading">History</h1>
          ) : (
            <h1 className="heading">Service History</h1>
          )}

{/* <div className="body-section">
  {!loading ? (
    <>
      {UseData.length > 0 ? (
        UseData.map((user, index) => (
          <UserDisplayCard
            key={`${user.user_id}_${index}`}
            image={user.user_photo}
            name={user.first_name || "No Name"}
            dob={user.age}
            category={user.service_title}
            user={user}
            stylistName={user.stylist_name}
            buttonText={"Continue"}
            background={
              user.SERVICE_SHORT_CODE === "1"
                ? "#FBF1E9"
                : user.SERVICE_SHORT_CODE === "2"
                ? "#ffffbc"
                : user.SERVICE_SHORT_CODE === "3"
                ? "#fee4cb"
                : user.SERVICE_SHORT_CODE === "4"
                ? "#EBF6FF"
                : "#ffffff"
            }
            onClick={() => onClick(user)}
          />
        ))
      ) : (
        <div className="nouserassigned">
          {LoginUser.ROLE === "0" ? (
            <h3>No user History</h3>
          ) : (
            <h3>No user Service History</h3>
          )}
        </div>
      )}
    </>
  ) : (
    <div className="loading">
      <Spin tip="Loading..." style={{ marginTop: "5%" }} />
    </div>
  )}
</div> */}

<div className="body-section">
  {!loading ? (
    <>
      {UseData.length > 0 ? (
        UseData.map((user, index) => {
          console.log("User:", user); // Logs each user object
          return (
            <UserDisplayCard
              key={`${user.user_id}_${index}`}
              image={user.user_photo}
              name={user.first_name || "No Name"}
              dob={user.age}
              category={user.service_title}
              user={user}
              stylistName={user.stylist_name}
              buttonText={"view"}
              background={
                user.SERVICE_SHORT_CODE === "1"
                  ? "#FBF1E9"
                  : user.SERVICE_SHORT_CODE === "2"
                  ? "#ffffbc"
                  : user.SERVICE_SHORT_CODE === "3"
                  ? "#fee4cb"
                  : user.SERVICE_SHORT_CODE === "4"
                  ? "#EBF6FF"
                  : "#ffffff"
              }
              onClick={() => onClick(user)}
            />
          );
        })
      ) : (
        <div className="nouserassigned">
          {LoginUser.ROLE === "0" ? (
            <h3>No user History</h3>
          ) : (
            <h3>No user Service History</h3>
          )}
        </div>
      )}
    </>
  ) : (
    <div className="loading">
      <Spin tip="Loading..." style={{ marginTop: "5%" }} />
    </div>
  )}
</div>


        </div>
      ) : (
        <>
          <UserProfile
            SelectUserData={selectviewuser}
            setBack={() => setshowDetails(!showDetails)}
            servicetype={"view"}
          />
        </>
      )}
    </>
  );
}

export default Servicehistory;
