import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spin, notification } from "antd";
import config from "../config";
import "./css/Accessories.css";
import {userReturn} from  "../utils/encryption";

const FootWear = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const fcmToken = sessionStorage.getItem("fcmToken");

  // State to store FootWear data, selected category, selected subcategory, selected items, previously selected items, and loading state
  const [FootWearData, setFootWearData] = useState([]);
  const [selectedOutfitCategory, setSelectedOutfitCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedItems, setSelectedItems] = useState([]); // Now an array for multiple selections
  const [previouslySelectedItems, setPreviouslySelectedItems] = useState([]);
  const [file, setFile] = useState(null); // State for file upload
  const [loading, setLoading] = useState(false); // Loading state

  // Fetch user FootWear data
  const getUserFootWearData = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getStylistFootwear/${SelectUserData.gender}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      if (response?.data?.recordset) {
        const fetchedData = response.data.recordset;
        setFootWearData(fetchedData);
        console.log(response);

        // Automatically select the first category and subcategory
        const firstCategory = fetchedData[0]?.OUTFIT_CATEGORY;
        const firstSubcategory = fetchedData[0]?.OUTFIT_TEXT;
        setSelectedOutfitCategory(firstCategory);
        setSelectedSubcategory(firstSubcategory);
      }
    } catch (error) {
      console.error("Error fetching FootWear data:", error);
      // notification.error({
      //   message: "Error",
      //   description: "Failed to load FootWear data.",
      // });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch previously selected items
  const getPreviouslySelectedItems = async () => {
    setLoading(true); // Start loading
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}getWebsiteSourceContent/${
          SelectUserData.user_id
        }/${"2"}/${SelectUserData.SERVICE_SHORT_CODE}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
        }
      );
      // console.log(response, "selectedImagesList");
      if (response?.data && response.data.length > 0) {
        const data = response.data[0];
       

        const selectedImagesList = JSON.parse(
          JSON.parse(data.SELECTED_IMAGES_DATA)
        );
        console.log(selectedImagesList, "selectedImagesList");
        setSelectedItems(selectedImagesList);
      }
    } catch (error) {
      console.error("Error fetching previously selected items:", error);
      // notification.error({
      //   message: "Error",
      //   description: "Failed to load previously selected items.",
      // });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    getPreviouslySelectedItems();
    getUserFootWearData();
  }, []);

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedOutfitCategory(category);
    const firstSubcategoryInCategory = FootWearData.find(
      (item) => item.OUTFIT_CATEGORY === category
    )?.OUTFIT_TEXT;
    setSelectedSubcategory(firstSubcategoryInCategory);
  };

  // Handle subcategory selection
  const handleSubcategorySelect = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  // Handle item selection (multi-select)
  const handleItemSelect = (category, item) => {
    setSelectedItems((prevState) => {
      const exists = prevState.some(
        (selectedItem) => selectedItem.ID === item.ID
      );
      if (exists) {
        return prevState.filter((selectedItem) => selectedItem.ID !== item.ID); // Remove if already selected
      }
      return [...prevState, item]; // Add new selection
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (selectedItems.length === 0) {
      notification.error({
        message: "No Images Selected",
        description: "Please select at least one image to upload.",
      });
      return;
    }
    if (selectedItems.length <= 1) {
      notification.error({
        message: "Minimum",
        description: "Please select at least Two image to upload.",
      });
      return;
    }

    setLoading(true); // Start loading

    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      //   const formData = new FormData();
      //   formData.append("userId", LoginUser?.id);
      //   formData.append("fcmToken", fcmToken);
      //   formData.append("selectedItems", JSON.stringify(selectedItems));

      //   if (file) {
      //     formData.append("file", file);
      //   }

      //   const response = await axios.post(`${config.baseURL}submitFootWear`, formData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   });

      console.log("Submit selectedItems:", selectedItems);

      const uploadData = new FormData();
      uploadData.append("CUSTOMER_ID", SelectUserData.user_id);
      uploadData.append("STYLIST_ID", SelectUserData.stylist_id);
      uploadData.append("SERVICE_ID", SelectUserData.SERVICE_SHORT_CODE);
      uploadData.append("USER_SERVICE_ID", SelectUserData.USER_SERVICE_ID);
      uploadData.append("LINK_LIST", JSON.stringify([]));

      selectedItems.forEach((image) => {
        uploadData.append("IMAGE_LIST", image.OUTFIT_IMAGE);
      });
      uploadData.append("SOURCE_CONTENT", JSON.stringify(selectedItems));
      uploadData.append("SELECTED_IMAGES_DATA", JSON.stringify(selectedItems));
      uploadData.append("type", "2");

      const uploadResponse = await axios.post(
        `${config.baseURL}websiteSourceContent`,
        uploadData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          Authorization: `Bearer ${accessToken}`, // Replace with actual token
          "X-CALLER": userReturn(LoginUser?.USER_ID),
        }
      );
      console.log(uploadData, "uploadData", selectedItems);
      if (uploadResponse.status === 200) {
        notification.success({
          message: "Upload Success",
          description: `${selectedItems.length} Images have been uploaded successfully.`,
        });
        getPreviouslySelectedItems();
      } else {
        notification.error({
          message: "Upload Failed",
          description: "Failed to upload images. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      notification.error({
        message: "Upload Error",
        description: "There was an error uploading images.",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Filter data based on the selected category and subcategory
  const filteredData = FootWearData.filter(
    (item) =>
      item.OUTFIT_CATEGORY === selectedOutfitCategory &&
      item.OUTFIT_TEXT === selectedSubcategory
  );

  // Extract unique categories and subcategories
  const uniqueCategories = [
    ...new Set(FootWearData.map((item) => item.OUTFIT_CATEGORY)),
  ];

  const subcategoriesInSelectedCategory = [
    ...new Set(
      FootWearData.filter(
        (item) => item.OUTFIT_CATEGORY === selectedOutfitCategory
      ).map((item) => item.OUTFIT_TEXT)
    ),
  ];
  function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  return (
    <>
      <div className="userProfileMain">
        <h1 className="heading">Foot Wear</h1>
      </div>
      <Spin spinning={loading}>
        <p style={{ margin: "13px 19px 0" }}>
          Note: Choose the images you would like to include in your collage.
        </p>
        <div className="FootWear-container" style={{ width: "100%" }}>
          {/* Dynamic Category Tabs */}
          {/* <div className="user-wardrobe scroll">
            {uniqueCategories.map((category, index) => (
              <button
                key={index}
                className={`button ${
                  selectedOutfitCategory === category ? "selected" : ""
                }`}
                onClick={() => handleCategorySelect(category)}
              >
                {category}
              </button>
            ))}
          </div> */}

          {/* Dynamic Subcategory Tabs */}
          {subcategoriesInSelectedCategory.length > 0 && (
            <div className="user-wardrobe scroll">
              {subcategoriesInSelectedCategory.map((subcategory, index) => (
                <button
                  key={index}
                  className={`button ${
                    selectedSubcategory === subcategory ? "selected" : ""
                  }`}
                  onClick={() => handleSubcategorySelect(subcategory)}
                >
                  {capitalizeFirstLetter(subcategory)}
                </button>
              ))}
            </div>
          )}

          {/* Submit Button */}
          {/* <div className="submit-container">
            <button
              // className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
              className="button"
            >
             
              Add
            </button>
          </div> */}
          {SelectUserData.status !== 4 && (
            <div className="submit-container" style={{ width: "100%" }}>
              <button
                // className="submit-btn"
                onClick={handleSubmit}
                disabled={loading}
                className="button"
              >
                {/* Submit */}
                Add
              </button>
            </div>
          )}

          {/* Subcategory Images (Multiple Selection) */}
          {/* {console.log(filteredData, "filteredData//.....")} */}
          <div className="subcategory-images">
            {filteredData.map((item, index) => (
              <div
                key={index}
                className={`subcategory-item ${
                  selectedItems.some(
                    (selectedItem) => selectedItem.ID === item.ID
                  )
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleItemSelect(selectedOutfitCategory, item)}
                style={{
                  border: selectedItems.some(
                    (selectedItem) => selectedItem.ID === item.ID
                  )
                    ? "2px solid blue"
                    : "1px solid #ccc",
                }}
              >
                <img
                  src={`${config.imageUrl}${item.OUTFIT_IMAGE}`}
                  alt={item.OUTFIT_IMAGE_DESC || item.OUTFIT_TEXT}
                />
                <p>{item.OUTFIT_TEXT}</p>
              </div>
            ))}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default FootWear;
