import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import Links from "../scrapinglinks/Links";
import { userReturn } from "../../utils/encryption";
const StyleSearch = ({ SelectUserData }) => {
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const fcmToken = sessionStorage.getItem("fcmToken");
  const [UserStyleSearchData, setUserStyleSearchData] = useState([]);

  const getUserStyleSearchData = async (userId) => {
    try {
      const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

      const response = await axios.get(
        `${config.baseURL}userSelectedAnswers/${userId}/SHOPPING_ASSISTANT`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(userId),
          },
        }
      );

      // Handle the response as needed
      console.log(response, "................");
      if (response?.data?.recordset) {
        console.log("....");
        const data = response?.data?.recordset;

        setUserStyleSearchData(data);
      }
      return response.data;
    } catch (error) {
      // Handle error
      console.error("Error fetching unassigned details:", error);
     // throw error;
    }
  };
  useEffect(() => {
    // getUserStyleSearchData(SelectUserData?.user_id);
  }, []);
  return (
    <div>
      <Links SelectUserData={SelectUserData}/>
    </div>
  );
};

export default StyleSearch;
