import React, { useState } from "react";
import { Badge } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  HomeOutlined,
  NotificationOutlined,
  CustomerServiceOutlined,
  SecurityScanOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LayoutRoutes from "../routes/LayoutRoutes";
import "./css/DashboardLayout.css";
import config from "../config";
import Profile from "../images/profile.jpg";
import logo from "../images/logo.svg";

const { Header, Sider, Content } = Layout;

const DashboardLayout = ({ notificationCount, clearNotificationCount }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const shouldIncludeManageUser = LoginUser?.ROLE === "0";
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const menuItems = [
    {
      key: "1",
      icon: <HomeOutlined />,
      path: "/dashboard",
      label: "Home",
    },
    {
      key: "3",
      icon: <NotificationOutlined />,
      path: "/dashboard/Notifications",
      label: "Notifications",
    },
    {
      key: "4",
      icon: <CustomerServiceOutlined />,
      path: "/dashboard/ServiceHistory",
      label: "History",
    },
    ...(shouldIncludeManageUser
      ? [
          {
            key: "5",
            icon: <UserOutlined />,
            path: "/dashboard/Manageuser",
            label: "Manage Stylists",
          },
        ]
      : []),
      ...(shouldIncludeManageUser
        ? [
            {
              key: "6",
              icon: <UserOutlined />,
              path: "/dashboard/UserManagement",
              label: "User Management",
            },
          ]
        : []),
    {
      key: "8",
      icon: <SecurityScanOutlined />,
      path: "/dashboard/ChangePassword",
      label: "Change Password",
    },
    {
      key: "9",
      icon: <UserOutlined />,
      path: "/dashboard/Profile",
      label: "Profile",
    },
  ];

  const handleLogout = async () => {
    await sessionStorage.clear();
    navigate("/login");
  };

  const logoutItem = {
    key: "9",
    icon: <LogoutOutlined />,
    path: "/dashboard/Logout",
    label: "Logout",
    onClick: handleLogout,
  };

  function extractBaseUrl(url) {
    const parts = url.split("https://").filter(Boolean);
    return `https://${parts[parts.length - 1]}`;
  }

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        className="DashboardLayout-Sider"
        id={collapsed ? "sidenavbarsmall" : "sidenavbar"}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: "#FBF1E9",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10%",
            }}
          >
            <img src={logo} width={"50%"} />
          </div>
          <Menu
            theme="light"
            mode="inline"
            className="DashboardLayout-Side-Menu"
            defaultSelectedKeys={["1"]}
            style={{
              color: "#000",
              fontSize: "18px",
            }}
            items={menuItems.map((item) => ({
              key: item.key,
              icon: React.cloneElement(item.icon, { style: { color: "#000" } }),
              label:
                item.key === "3" ? (
                  <Link to={item.path} onClick={clearNotificationCount}>
                    <span>
                      {item.label}
                      {notificationCount > 0 && (
                        <Badge
                          count={notificationCount}
                          style={{ marginLeft: 8 }}
                        />
                      )}
                    </span>
                  </Link>
                ) : (
                  <Link to={item.path}>{item.label}</Link>
                ),
            }))}
          />
        </div>
        <Menu theme="light" mode="inline" className="DashboardLayout-Side-Menu">
          <div
            style={{
              display: "flex",
              margin: "0px 29px",
              fontSize: "11px",
              flexDirection: "column",
            }}
          >
            <p> Version :- 2.0.0</p>
            <p style={{ fontSize: "10px", marginTop:"3px", color: "#666" }}>
      Build Date: 25-03-2025
    </p>
          </div>
          <Menu.Item
            key={logoutItem.key}
            icon={logoutItem.icon}
            onClick={logoutItem.onClick}
          >
            {logoutItem.label}
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="header"
          style={{
            padding: "0 16px",
            background: "#FBF1E9",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100vw",
              justifyContent: "space-between",
              paddingRight: "3%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <p style={{ fontSize: "18px", fontWeight: "600" }}>
                {LoginUser?.ROLE === "0"
                  ? "Welcome to Admin Dashboard"
                  : "Welcome to Stylist Dashboard"}
              </p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 16 }}
            >
              <img
                src={`${
                  LoginUser?.USER_PHOTO
                    ? extractBaseUrl(
                        `${config.imageUrl}${LoginUser?.USER_PHOTO}`
                      )
                    : Profile
                }`}
                alt=""
                width={"50px"}
                height={"50px"}
                style={{ marginRight: 8, borderRadius: "50%" }}
              />
              <p style={{ fontSize: " 20px", margin: 0 }}>
                {capitalizeFirstLetter(
                  LoginUser?.FIRST_NAME ? LoginUser?.FIRST_NAME : "No name "
                )}
              </p>
              <p
                style={{
                  marginLeft: "5px",
                  fontSize: " 13px",
                  color: "#000000b0",
                  marginBottom: " 0px",
                }}
              >
                {LoginUser?.ROLE === "0" ? "Admin" : "Stylist"}
              </p>
            </div>
          </div>
        </Header>

        <Content
          className="mainContainerDiv"
          style={{
            margin: "24px 16px",
            minHeight: 280,
            borderRadius: borderRadiusLG,
          }}
        >
          <LayoutRoutes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;