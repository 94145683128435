
// const baseURL = "http://skanda.pragmatiqinc.com/user/";
// const imageUrl = "http://skanda.pragmatiqinc.com";
// const questionsURL = "http://skanda.pragmatiqinc.com/user/getQuestions/";
// const newQuestionsURL = "http://skanda.pragmatiqinc.com/user/questionnaires/";
// const newQuestionAndAnswersURL = "http://skanda.pragmatiqinc.com/user/getQuestionsAndAnswers/";


//  // skanda QA
 const baseURL = "https://skandaqa.stylz.in/v1/";
 const baseURL1 = "https://skandaqa.stylz.in/user/";
 const imageUrl = "https://skandaqa.stylz.in";
 const questionsURL = "https://skandaqa.stylz.in/user/getQuestions/";
 const newQuestionsURL = "https://skandaqa.stylz.in/user/questionnaires/";
 const newQuestionAndAnswersURL = "https://skandaqa.stylz.in/user/getQuestionsAndAnswers/";


 // skanda prd

//  const  baseURL = "https://skanda.stylz.in/user/";
//  const  imageUrl = "https://skanda.stylz.in";
//  const  questionsURL = "https://skanda.stylz.in/user/getQuestions/";
//  const  newQuestionsURL = "https://skanda.stylz.in/user/questionnaires/";
//  const  newQuestionAndAnswersURL = "https://skanda.stylz.in/user/getQuestionsAndAnswers/";

  const routes = {
  };


  

export default { routes, baseURL,imageUrl,questionsURL,newQuestionAndAnswersURL ,newQuestionsURL, baseURL1};
