import React from "react";
import { FaArrowRight } from "react-icons/fa"; 
import { FaRegStar } from "react-icons/fa"; // Optional: for feedback icon
import "./css/purchaseHistory.css";

const PurchasedHistory = ({ label, value, description, onClick, feedback }) => {
  return (
    <div className="purchased-box" onClick={onClick}>
      <div className="purchased-header">
        <div className="purchased-label">{label}</div>
        {feedback && (
          <div className="purchased-feedback-icon">
            <FaRegStar /> {/* Feedback icon */}
          </div>
        )}
      </div>
      <div className="purchased-description">{description}</div>
      <div className="purchased-divider"></div>
      <div className="purchased-footer">
        <div className="purchased-count">{value}</div>
        <div className="purchased-explore-icon">
          <FaArrowRight />
        </div>
      </div>
    </div>
  );
};

export default PurchasedHistory;
