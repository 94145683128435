import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Flex } from "antd";
import axios from "axios";
import config from "../../config";
import NotificationCard from "./NotificationCard";
import "./Notification.css";
import { userReturn } from "../../utils/encryption";
function Notification({ SelectUserData }) {
  const [loading, setloading] = useState(true);
  const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));
  const [UseData, setUserData] = useState([]);
  // const getUserData = async (userId) => {
  //   const endpointUrl =
  //     LoginUser.ROLE === "0" ? "adminNotification" : "notificationRecieved1";

  //   try {
  //     const response = await axios.get(`${config.baseURL}${endpointUrl}, {
  //         params: {
  //           USER_ID: 123,
  //           APP_TYPE: "APP_TYPE_VALUE",
  //           SERVICE_ID: "SERVICE_ID_VALUE",
  //           USER_SERVICE_ID: "USER_SERVICE_ID_VALUE",
  //           STYLIST_ID: 31,
  //         },`);

  //     // console.log(response, "................");
  //     if (response?.data?.recordset) {
  //       // console.log("....");
  //       const data = response?.data?.recordset;
  //       setUserData(data);
  //       setloading(false);
  //     }
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error fetching unassigned details:", error);
  //     throw error;
  //   }
  // };
  const getUserData = async (userId) => {
    const accessToken = sessionStorage.getItem("accessToken"); // Retrieve token from sessionStorage

    const endpointUrl =
      LoginUser.ROLE === "0" ? "adminNotification" : "notificationRecieved1";

    try {
      const params =
        LoginUser.ROLE !== "0"
          ? {
              USER_ID: LoginUser.USER_ID,
            }
          : undefined;

      const response = await axios.get(`${config.baseURL}${endpointUrl}`, {
        params,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Replace with actual token
            "X-CALLER": userReturn(LoginUser?.USER_ID),
          },
      
      });
      console.log(response?.data.recordset, "reponse in notifications");

      console.log("role", LoginUser.ROLE)
      const data = response?.data?.recordset;
      console.log(data, "data in repsonce")

      setUserData(data);
      setloading(false);
      return response.data;
    } catch (error) {
      console.error("Error fetching unassigned details:", error);
      throw error;
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour time format
    hours = hours % 12;
    hours = hours ? hours : 12; // handle 00 hour as 12 AM

    return `${day} ${month}, ${year} ${hours}:${minutes}${ampm}`;
  }

  function formatDateToDDMMYY(isoString) {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  }

  return (
    <div className="userProfileMain" style={{ background: "transparent" }}>
      <h1 className="heading">Notifications</h1>
      <div className="body-section">
        {!loading ? (
          <Row gutter={[16, 16]} className="notification-main-grid">
            {UseData.length > 0 ? (
                UseData.map((notification, index) => {
                  // Map SERVICE_ID to corresponding notification type
                  const serviceTypes = {
                    1: "Shopping Assistant",
                    2: "Wardrobe Assistant",
                    3: "Wedding Assistant",
                    4: "Capsule Wardrobe",
                  };
                  const notificationType =
                    serviceTypes[notification.SERVICE_ID] || "General";
    
                  return (
                    <NotificationCard
                      key={index}
                      serviceId={notification.USER_SERVICE_ID}
                      userName={notification.CUSTOMER_NAME || "Unknown"}
                      timeAgo={formatDateToDDMMYY(notification.NOTI_SENT_DATE)}
                      title={notification.NOTI_TITLE}
                      body={notification.NOTI_BODY}
                      notificationType={notificationType}
                      receiver={notification.USER_ID}
                    />
                  );
                })
              ): (
                <p className="no-notifications">No notifications available</p>
              )}
          </Row>
        ) : (
          <div className="loading">
            <Spin tip="Loading..." style={{ marginTop: "5%" }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Notification;

// import React, { useState, useEffect } from "react";
// import { Row, Col, Spin, Tabs } from "antd";
// import axios from "axios";
// import config from "../../config";
// import "./Notification.css";

// const { TabPane } = Tabs;

// function Notification() {
//   const [loading, setLoading] = useState(true);
//   const [useData, setUserData] = useState([]);
//   const LoginUser = JSON.parse(sessionStorage.getItem("LoginUser"));

//   const getUserData = async () => {
//     const endpointUrl =
//       LoginUser.ROLE === "0" ? "adminNotification" : "notificationRecieved1";

//     try {
//       const params =
//         LoginUser.ROLE !== "0"
//           ? {
//               USER_ID: LoginUser.USER_ID,
//             }
//           : undefined;

//       const response = await axios.get(`${config.baseURL}${endpointUrl}`, {
//         params,
//       });

//       const data =
//         LoginUser.ROLE !== "0" ? response?.data : response?.data?.recordset;

//       setUserData(data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching notifications:", error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getUserData();
//   }, []);

//   function formatDateToDDMMYY(isoString) {
//     const date = new Date(isoString);

//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = String(date.getFullYear()).slice(-2);

//     return `${day}/${month}/${year}`;
//   }

//   // Group notifications by NOTI_TITLE
//   const groupedNotifications = useData.reduce((acc, item) => {
//     acc[item.NOTI_TITLE] = acc[item.NOTI_TITLE] || [];
//     acc[item.NOTI_TITLE].push(item);
//     return acc;
//   }, {});

//   return (
//     <div className="userProfileMain">
//       <h1 className="heading">Notification</h1>
//       <div className="body-section">
//         {!loading ? (
//           <Tabs defaultActiveKey="1">
//             {Object.keys(groupedNotifications).map((title) => (
//               <TabPane tab={title} key={title}>
//                 <Row gutter={[16, 16]}>
//                   {groupedNotifications[title].map((user) => (
//                     <Col xs={24} sm={12} md={12} lg={8} key={user.NOTIFICATION_ID}>
//                       <div className="notify">
//                         <div className="notifybody">
//                           <div className="notiTitle-Div">
//                             <div className="notiTitle">{user.NOTI_TITLE}</div>
//                           </div>
//                           <div className="Notisub-Div">
//                             <p>{user.NOTI_BODY}</p>
//                             <div className="Notification-Content">
//                               <p>Date:</p>
//                               <div>
//                                 {formatDateToDDMMYY(user.NOTI_SENT_DATE)}
//                               </div>
//                             </div>
//                             <div className="Notification-Content">
//                               <p>Service Name:</p>
//                               <div>
//                                 {user.SERVICE_ID === "1"
//                                   ? "Shopping"
//                                   : user.SERVICE_ID === "2"
//                                   ? "Wardrobe"
//                                   : user.SERVICE_ID === "3"
//                                   ? "Wedding"
//                                   : user.SERVICE_ID === "4"
//                                   ? "Capsule"
//                                   : user.SERVICE_ID}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Col>
//                   ))}
//                 </Row>
//               </TabPane>
//             ))}
//           </Tabs>
//         ) : (
//           <div className="loading">
//             <Spin tip="Loading..." style={{ marginTop: "5%" }} />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Notification;
